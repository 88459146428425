.login-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.blue-column {
  display: none;
}

h2 {
  text-align: center;
}

.login-form {
  padding: 1rem;
}

.login-form input {
  margin-top: 2rem;
  width: 25rem;
  height: 3rem;
  border-radius: 5px;
  background-color: #cccccc;
  font-size: 1.1rem;
  padding-left: 1rem;
  border: 1px solid #cccccc;
}

.login-form button {
  background: none;
  border: none;
  border-radius: 5px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #303655;
  margin-top: 2rem;
  color: white;
  width: 25rem;
  height: 3rem;
}

.error-message {
  color: var(--red);
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
}

@media (min-width: 700px) {
  .blue-column {
    display: initial;
    height: 100vh;
    background-color: #303655;
  }
  .login-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    align-items: center;
  }
  .login-form button {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .login-form input {
    width: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
