.navbar {
  min-height: 100vh;
  background-color: var(--darkGray);
  display: 'flex';
  flex-direction: 'column';
  align-items: 'stretch';
  justify-content: 'space-between';
}

.navbarIcon {
  color: var(--lightGray);
  padding-top: '.425rem';
  padding-bottom: '.425rem';
}

.navbarBrand {
  padding-left: '.5625rem';
  padding-right: '.5625rem';
  padding-top: '1.5rem';
}

.navbarMenu {
  background-color: var(--darkGray) !important;
  color: #ffffff !important;
}

.subNav {
  left: '4.125rem';
  background-color: #e7e7e7;
}

.subNavIcon {
  vertical-align: 'middle';
  margin-right: '.3125rem';
}

.subMenu {
  background-color: #d7d7d7;
  box-shadow: 'none';
  border: 'none';
}

.activeSubMenu {
  background-color: #d7d7d7;
}

.menuItem {
  color: white !important;
  font-size: 1.2rem;
}

.menuImg {
  color: white !important;
  font-size: 1.2rem;
  padding: 2em 1em 2em 0 !important;
}

@media only screen and (max-width: 840px) {
  .menuItem {
    display: none !important;
  }
}

@media only screen and (min-width: 840px) {
  .menuImg {
    display: none !important;
  }
}

.menuItem:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.active .menuItem {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
