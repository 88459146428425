@import '../styles/mixins';

.vendorSearch {
  @include for-phone-only {
    display: grid;
    gap: 8px;
  }
  display: flex;
  flex-direction: row;
  flex: 1;
}

.vendorButton {
  @include for-phone-only {
    padding-top: 1em !important;
  }
}
