@import '../styles/mixins';

.routesButton {
  margin-bottom: 0.5em !important;
}

.customerButton {
  @include for-tablet-portrait-max;
}

.newCustomerInputField {
  @include for-md-device-width {
    padding: 0.5em;
  }
}

.newCustomerInputFieldHalf {
  @include for-md-device-width {
    width: 50%;
    display: inline-block;
    padding: 0.5em;
  }
}

.newCustomerInputFieldSmall {
  @include for-md-device-width {
    width: 33%;
    display: inline-block;
    padding: 0.5em;
  }
}

.newCustomerSubmitBtn {
  @include for-md-device-width {
    padding: 0.5em;
  }
}

.mobileText h2 {
  @include for-phone-only {
    font-size: medium !important;
  }
}

.label {
  @include for-phone-only {
    width: screen !important;
  }
}

.newJobButton {
  padding-top: 1em;
}

.dashboardContainer {
  min-width: 1250px;
  @include for-phone-only {
    min-width: 12vw !important;
  }
}

.linkButton {
  border: none;
  background-color: white;
  color: blue;
  padding: 0;
}

.linkButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
