@import './variables';

@mixin for-phone-only {
  @media (max-width: $phoneMaxWidth) {
    @content;
  }
}

@mixin for-small-phone-only {
  @media (max-width: $phoneSmlMaxWidth) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: $tabletPortraitMinWidth) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (max-width: $tabletPortraitMinWidth) {
    @content;
  }
}

@mixin for-tablet-portrait-max {
  @media (max-width: $tabletPortraitMaxWidth) {
    width: 100px;
    height: 40px;
    font-size: 12px !important;
    margin: 3px !important;
  }
}

@mixin for-tablet-landscape-down {
  @media (max-width: $tabletLandscapeMinWidth) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $tabletLandscapeMinWidth) {
    @content;
  }
}

@mixin for-tablet-landscape-max {
  @media (max-width: $tabletLandscapeMaxWidth) {
    @content;
  }
}

@mixin for-small-desktop-up {
  @media (min-width: $smallDesktopMinWidth) {
    @content;
  }
}

@mixin for-small-desktop-down {
  @media (max-width: $smallDesktopMinWidth) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $desktopMinWidth) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: $desktopMinWidth) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: $bigDesktopMinWidth) {
    @content;
  }
}

@mixin max-table-width {
  max-width: $table-max-width !important;
  width: $table-width !important;
  margin: 0 auto;

  @media (min-width: $giantDesktopMinWidth) {
    max-width: 2200px !important;
  }
}

@mixin for-md-device-width {
  @media (min-width: $mediumDevicesMinWidth) {
    @content;
  }
}
